.rec-dot_active {
    background-color: #4EE1A0 !important;
    box-shadow: 0 0 1px 3px #4EE1A0 !important;
}
.rec-dot:focus,
.rec-dot:hover {
    box-shadow: 0 0 1px 3px white !important;
}

.rec-dot {
    box-shadow: 0 0 1px 1px white !important;
}
.rec-dot_active:focus,

.rec-dot_active:hover {
    background-color: #4EE1A0 !important;
    box-shadow: 0 0 1px 3px #4EE1A0 !important;
}

.rec-arrow {
    
    background-color: var(--blue) !important;
    transition: all 0.3s ease;
    font-size: 1.7rem !important;
    color: white !important;
    width: 1.7rem !important;
    height: 1.7rem !important;
    min-width: 1.7rem !important;
    line-height: 1.7rem !important;
    /* color: white !important; */
   
}

.rec-arrow:hover:not(:disabled) {
    transform: scale(1.3);
    /* color */
}